@import '~bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

*{
    padding: 0px;
    margin: 0px;
    font-family:Arial, Helvetica, sans-serif;
}
.nospacing{
    padding: 0px;
    margin: 0px;
}
/* GOING UP BUTTOM */
.goingUp{
    position: fixed;
    top: 750px;
    right: 30px;
    font-size: 60px;
    color: rgb(141, 140, 140);
    z-index: 2;

}
.max-width-1400 {
    max-width: 1400px;
    margin: 0 auto;
}

.goingUp:hover{
    color: #ED1C8F;
    cursor: pointer;
    transition: all 500ms;
}

/* MESSENGER FIXER */

/* CLASE ACTIVA PRESS */


.messenger{
    position: fixed;
    top: 850px;
    right: 25px;
    font-size: 70px;
    color:#0084FF;
    z-index: 2;

}
.w-1400 {
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 2em;
}
.checkbtn{
    color:rgb(141, 140, 140);
    font-size: 30px;
    cursor: pointer;
    float: left;
    line-height: 115px;
    margin-right: 10px;
    display: none;
}
#check{
    display: none;
}
.position-relative {
    position: relative;
}
/* @media(max-width:1355px){
    .checkbtn{
        display: block;
        margin-left:30px ;
    }
    .carousel-item,.carouselExampleIndicators,.carousel,
    .carousel-indicators,.carousel-inner{
        height: fit-content;
        z-index: 1;
        
    }
    #menu{
        position: relative;
        z-index: 2;
        background-color: black;
        border: 0px solid white !important;
    }
    nav ul{
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: black;
        top: 120px;
        text-align: center;
        left:-100%;
        transition: all 500ms;
    }
    
    #logo{
        width: 90%;
        
    }
    #logo a img{
        display: block;
        margin: 0px auto;

    }
    nav ul li{
        width: 100%;
        display: block;
        
    }

    nav ul li a{

        display: block;
        border: 0px solid white !important;
        border-top: 1px solid white !important;
    }

    #check:checked ~ ul{
        left: 0;
    }


}
 */


/* CARRUSEL */

.carousel img{
    height: 95%;
}


/* CONTENEDOR DE PRINCIPAL */
.main-container{
    width: 70%;
    margin: 0px auto;
}
.padding-skip-navbar {
    padding-top: 10em;

}
.mineContainer{
    width: 70%;
    margin: 100px auto;

    @media(max-width: 1200px){
        width: 90%;
    }
}
.mineContainer .caja{
    border-top: 1px solid #1E73BE !important;
    border-bottom: 1px solid #1E73BE !important;
    
}
.mineContainer p{
    text-align: justify;
    font-size: 20px;
    font-weight: 500;
}
.mineContainer img{
    width: 100%;
    margin-top: 20px;
}

.socialNetworksInicio{
    font-size: 25px;
    color: rgb(141, 140, 140) ;
    margin-right: 20px;
}
.socialNetworksInicio:hover{
    color: #ED1C8F;
    transition: all 500ms;
}
.btn1{
    color: #444 !important;
    background-color: #ccc;
    padding: 20px;
    border-radius: 50px;
    text-decoration: none !important;
    border:2px solid #444;
    letter-spacing: 2px
}
.btn1:hover{
    cursor: pointer;
    background-color: rgb(182, 179, 179);
    transition: all 500ms;
}

.btn2{
    color: white !important;
    background-color: #ED1C8F;
    padding: 20px;
    border-radius: 50px;
    text-decoration: none !important;
    border:2px solid #444;
    letter-spacing: 2px;
    
}
.btn2:hover{
    cursor: pointer;
    background-color: rgb(0, 0, 0);
    transition: all 500ms;
}
/* FOOTER O PIE DE PAGINA */

footer{
    background-color: #282828;
    height: 400px;
}

.footerContent{
    width: 100%;
    display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}

.footerContent p{
    color: rgb(141, 140, 140) ;
}
.copy {
    height: 40px;
    background-color: rgba(0,0,0);
    color: rgb(141, 140, 140);
    text-align: center;
    line-height: 40px;
    overflow: hidden;
}